<script setup lang="ts">
    import {
        Dialog as HDialog,
        DialogDescription as HDialogDescription,
        DialogPanel as HDialogPanel,
        DialogTitle as HDialogTitle,
        TransitionChild as HTransitionChild,
        TransitionRoot as HTransitionRoot,
    } from '@headlessui/vue'

    const dialogStore = useDialogStore()

    const CloseButtonRef = useTemplateRef<HTMLElement>('CloseButtonRef')
</script>

<template>
    <h-transition-root appear :show="dialogStore.isOpen" as="template">
        <h-dialog
            as="div"
            class="relative z-20"
            :initial-focus="CloseButtonRef"
            @close="dialogStore.dialogState.allowOutsideClick ? dialogStore.closeDialog() : undefined"
        >
            <h-transition-child
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
                <div class="fixed inset-0 bg-gray-500/75 dark:bg-slate-700/75" />
            </h-transition-child>

            <div class="fixed inset-0 overflow-y-auto">
                <div class="flex min-h-screen items-end justify-center p-4 text-center sm:items-center">
                    <h-transition-child
                        as="template"
                        enter="duration-300 ease-out"
                        enter-from="opacity-0 scale-95"
                        enter-to="opacity-100 scale-100"
                        leave="duration-200 ease-in"
                        leave-from="opacity-100 scale-100"
                        leave-to="opacity-0 scale-95"
                    >
                        <h-dialog-panel
                            class="inline-block w-full max-w-md overflow-hidden rounded-lg bg-gray-100 p-4 text-center align-bottom shadow-xl transition-all dark:bg-slate-900 sm:my-3 sm:text-left sm:align-middle"
                        >
                            <h-dialog-title v-if="dialogStore.dialogState.title" class="text-lg font-medium leading-6">
                                {{ dialogStore.dialogState.title }}
                            </h-dialog-title>

                            <div v-if="dialogStore.dialogState.text" :class="[{ 'mt-2': dialogStore.dialogState.title }]">
                                <h-dialog-description class="whitespace-pre-line text-sm">
                                    {{ dialogStore.dialogState.text }}
                                </h-dialog-description>
                            </div>

                            <div class="mt-5 flex flex-col gap-2 sm:mt-4 sm:flex-row sm:flex-wrap sm:justify-end">
                                <a-button
                                    v-if="dialogStore.dialogState.confirmText"
                                    :color="dialogStore.dialogState.confirmColor"
                                    @click="dialogStore.closeDialog(dialogStore.dialogState.onConfirm)"
                                >
                                    {{ dialogStore.dialogState.confirmText }}
                                </a-button>

                                <a-button
                                    v-if="dialogStore.dialogState.denyText"
                                    :color="dialogStore.dialogState.denyColor"
                                    @click="dialogStore.closeDialog(dialogStore.dialogState.onDeny)"
                                >
                                    {{ dialogStore.dialogState.denyText }}
                                </a-button>

                                <a-button
                                    v-if="!dialogStore.dialogState.noCancelButton"
                                    ref="CloseButtonRef"
                                    @click="dialogStore.closeDialog(dialogStore.dialogState.onCancel)"
                                >
                                    {{ dialogStore.dialogState.cancelText }}
                                </a-button>
                            </div>
                        </h-dialog-panel>
                    </h-transition-child>
                </div>
            </div>
        </h-dialog>
    </h-transition-root>
</template>
