export const useCoreStore = defineStore('core', {
    state: () => ({
        version: __APP_VERSION__,
        darkMode: useDark(),
        appMounted: false,
        coreLoaded: false,
        round: <Api.ApiCoreResponse['default_round']>100,
        sucursales: <Api.ApiCoreResponse['sucursales']>[],
        vendedores: <Api.ApiCoreResponse['vendedores']>[],
        vendedores_activos: <Api.ApiCoreResponse['vendedores_activos']>null,
        localidades: <Api.ApiCoreResponse['localidades']>[],
        mediosPagos: <Api.ApiCoreResponse['medios_pago']>[],
        bancos: <Api.ApiCoreResponse['bancos']>[],
        tarjetas: <Api.ApiCoreResponse['tarjetas']>[],
        promos: <Api.ApiCoreResponse['promos']>[],
    }),

    getters: {
        isDark: state => state.darkMode,
        tipoDocumentos: () => [
            { tipo: 'DNI', tipoLargo: 'DNI', id: 96 },
            { tipo: 'CI', tipoLargo: 'CI EXTRANJERA', id: 91 },
        ],
        ventaFiscal: () => [
            { tipo: 'Factura B', id: 6 },
            { tipo: 'Factura A', id: 1 },
        ],
        bajasOptions: (): { id: App.Models.VendedorBajaEnum, value: string }[] => [
            { id: 1, value: 'Renunció' },
            { id: 2, value: 'Desvinculado sin Causa' },
            { id: 3, value: 'Desvinculado con Causa' },
        ],
        salaryTypesOptions: () => [
            { id: 1, tipo: 'Sueldo' },
            { id: 2, tipo: 'Vacación' },
            { id: 3, tipo: 'Aguinaldo' },
            { id: 4, tipo: 'Vacaciones no gozadas' },
            { id: 5, tipo: 'Nota Sueldo' },
            { id: 7, tipo: 'Nota Vacaciones' },
            { id: 6, tipo: 'Nota Aguinaldo' },
            { id: 8, tipo: 'Sueldo Integrativo' },
            { id: 9, tipo: 'Preaviso' },
            { id: 13, tipo: 'Sueldo Extraordinario' },
            { id: 14, tipo: 'Nota Indemnización' },
        ],
        dataTableOperators: (): DataTable.Operators[] => [
            { operator: 'like', description: 'Contiene' },
            { operator: 'not like', description: 'No contiene' },
            { operator: '=', description: 'Igual que' },
            { operator: '!=', description: 'No es igual que' },
            { operator: 'null', description: 'Es nulo' },
            { operator: 'not_null', description: 'No es nulo' },
        ],
        dataTableOperatorsExpanded(): DataTable.Operators[] {
            return [
                ...this.dataTableOperators,
                { operator: '>', description: 'Mayor que' },
                { operator: '<', description: 'Menor que' },
                { operator: '>=', description: 'Mayor o igual que' },
                { operator: '<=', description: 'Menor o igual que' },
            ]
        },
        colors: (): { id: App.Models.ColorsEnum, color: string }[] => [
            { id: 'default', color: 'Negro' },
            { id: 'blue', color: 'Azul' },
            { id: 'green', color: 'Verde' },
            { id: 'gray', color: 'Gris' },
            { id: 'orange', color: 'Naranja' },
            { id: 'pink', color: 'Rosado' },
            { id: 'purple', color: 'Purpura' },
            { id: 'red', color: 'Rojo' },
            { id: 'yellow', color: 'Amarillo' },
        ],
        activePromos: (state): Pick<App.Models.Promo, 'id' | 'promo'>[] => {
            const weekday = DateTime.now().weekday

            return state.promos.filter(promo => promo.days_bitmap == 0 || Boolean(promo.days_bitmap & (1 << weekday))).map(promo => ({ id: promo.id, promo: promo.promo }))
        },
    },

    actions: {
        setAppMounted() {
            this.appMounted = true
        },

        toggleDark() {
            this.darkMode = !this.darkMode
        },

        async fetchCoreData(force = false) {
            if (!this.coreLoaded || force) {
                this.appMounted = false
                this.coreLoaded = false

                await window.axios.get<Api.ApiCoreResponse>('/api/core').then((response) => {
                        this.round = response.data.default_round
                        this.sucursales = response.data.sucursales
                        this.vendedores = response.data.vendedores
                        this.vendedores_activos = response.data.vendedores_activos
                        this.localidades = response.data.localidades
                        this.mediosPagos = response.data.medios_pago
                        this.bancos = response.data.bancos
                        this.tarjetas = response.data.tarjetas
                        this.promos = response.data.promos

                        this.coreLoaded = true
                        this.setAppMounted()
                    }).catch((error) => {
                        console.error(error)
                    })

                useCacheStore().resetStore()
            }
        },

        async regenerateStock() {
            const authStore = useAuthStore()

            if (!authStore.admin) {
                return
            }

            await window.axios.get('/api/core/stock/regenerate').then(() => {
                    pushNotification.success('Stock Regenerado')
                }).catch((error) => {
                    console.error(error)
                })
        },

        async cacheClear() {
            if (useAuthStore().admin) {
                await window.axios.get('/api/core/cache-clear').then(() => {
                        pushNotification.success('Cache purgado')
                    }).catch((error) => {
                        console.error(error)
                    })
            }

            useCacheStore().resetStore()

            await this.fetchCoreData(true)
        },
    },

    share: {
        enable: true,
    },
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useCoreStore, import.meta.hot))
}
